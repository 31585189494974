import React, { useEffect } from "react";

import { WorktypeContainer } from "./Worktype.styles";

const Worktype = (props) => {
  //PROPS
  const { locale, filters, skills, subFilter, setSubFilter } = props;
  //PROPS

  //STATES
  useEffect(() => {
    setSubFilter(filters);
  }, [filters]);

  //STATES

  return (
    <WorktypeContainer>
      <li className={!subFilter ? "active" : ""}>
        <button
          onClick={() => {
            setSubFilter(null);
          }}
        >
          {locale === "PT" ? "Tudo" : "All"}
        </button>
      </li>
      {skills.map((item, index) => {
        const isPhoto = item === "photographer" || item === "fotografo";

        return (
          <li
            className={
              (isPhoto && subFilter === "photographer") ||
              (!isPhoto && subFilter === "director")
                ? "active"
                : ""
            }
            key={index}
          >
            <button
              onClick={() => {
                isPhoto
                  ? setSubFilter("photographer")
                  : setSubFilter("director");
              }}
            >
              {}
              {item === "director"
                ? locale === "PT"
                  ? "Filme"
                  : "Film"
                : item === "photographer"
                ? locale === "PT"
                  ? "Fotografia"
                  : "Photography"
                : null}
            </button>
          </li>
        );
      })}
    </WorktypeContainer>
  );
};

export default Worktype;
