import styled from "styled-components";
import { toRem } from "utils/mixins";
import { h5 } from "theme/typography";
import { black } from "theme/colors";

export const WorktypeContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  li {
    ${h5}
    margin: 0 ${toRem(10)};
    cursor: pointer;
    button {
      text-transform: uppercase;
      position: relative;
      cursor: pointer;
      transition: 250ms;
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: ${toRem(1)};
        display: block;
        margin-top: 0;
        right: 0;
        background-color: ${black.default};
        transition: 0.4s ease;
      }
      &:hover {
        /* opacity: 0.2; */
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
    &.active button {
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }
`;
