import React, { useState } from "react";
import { graphql } from "gatsby";

import HTMLReactParser from "html-react-parser";

import Grid from "components/grid";
import Categories from "components/categories";
import Showreel from "components/showreel";
import Worktype from "components/worktype";
import InView from "react-intersection-observer";

const TalentPage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        title,
        skills,
        talents_info: { bio, works, socialMedia }
      }
    },
    pageContext: {
      language: { code }
    },
    isBlack,
    dimensions,
    setBlack,
    setLogo
  } = props;
  //PROPS

  //STATES
  const [subFilter, setSubFilter] = useState();
  const [category, setCategory] = useState(null);
  //STATES

  //DEFS

  let chars =
    works &&
    works.map((e) => {
      return e.work_info.workType;
    });
  let uniqueChars = [...new Set(chars)];

  //console.log(uniqueChars);

  const filters = props.skills;
  const locale = code;
  const isMultiSkill = uniqueChars[1];

  let arr = [];

  works &&
    works.map((e) => {
      return e.work_info.workType === "photographer"
        ? e.categories.nodes.map((e) => arr.push(e.name))
        : null;
    });

  let PhotoCategories = [];

  PhotoCategories = arr.filter(function (item, pos) {
    return arr.indexOf(item) === pos;
  });

  const isPhotographer = ["dGVybToxMg==", "dGVybToxOA=="].includes(
    skills.nodes[0].id
  );
  const hasCategories =
    !isMultiSkill && PhotoCategories.length > 1 && isPhotographer;
  //DEFS

  return (
    <>
      <main className="main">
        <h1
          className={
            hasCategories || isMultiSkill
              ? "h1-underline"
              : "h1-underline h1-underline--72"
          }
        >
          {title}
        </h1>

        {isMultiSkill && (
          <Worktype
            locale={locale}
            filters={filters}
            skills={uniqueChars}
            subFilter={subFilter}
            setSubFilter={setSubFilter}
          />
        )}

        {/* {hasCategories && (
          <Categories
            locale={locale}
            setCategory={setCategory}
            category={category}
            data={works}
          />
        )} */}

        <Grid
          locale={locale}
          data={works}
          hasFilter={true}
          type="talent"
          dimensions={dimensions}
          subFilter={subFilter}
          workType={category}
          skills={uniqueChars}
        />

        <div className="container container--800 container--bio">
          {!!bio && HTMLReactParser(bio)}
          {socialMedia && (
            <ul className="smedia__container">
              {socialMedia.map((e, index) => {
                return (
                  <li key={index}>
                    <a href={e.link} target="_blank" rel="noreferrer">
                      <img
                        src={e.icon?.localFile?.childImageSharp.fluid.src}
                        alt={e.icon?.altText}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <InView
          as="div"
          onChange={(inView, entry) => {
            if (inView) {
              setLogo(true);
            } else {
              setLogo(false);
            }
          }}
          threshold={0.8}
        >
          <Showreel
            dimensions={dimensions}
            code={locale}
            skills={props.skills}
            isBlack={isBlack}
            setBlack={setBlack}
          />
        </InView>
      </main>
    </>
  );
};

export const query = graphql`
  query talentPage($id: String) {
    page: wpTalent(id: { eq: $id }) {
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      title
      skills {
        nodes {
          id
          name
        }
      }
      talents_info {
        works {
          ... on WpPost {
            uri
            categories {
              nodes {
                name
                id
              }
            }
            work_info {
              workType
              title
              shortDescription
              client
              vimeoLink
              availableOnTheSite
              content {
                ... on WpPost_WorkInfo_Content_Video {
                  vimeo
                  previewVideo {
                    mediaItemUrl
                  }
                }
              }
              coverImage {
                altText
                mediaDetails {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              coverVideo {
                mediaItemUrl
              }
            }
          }
        }
        bio
        socialMedia {
          link
          icon {
            altText
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default TalentPage;

// let isav = types.nodes.map((e) => e.slug).includes("oficial");

/* {socialMedia && (
            <ul className="smedia__container">
              {socialMedia.map((e) => {
                switch (e.link.title) {
                  case "facebook":
                    return (
                      <li>
                        <a href={e.link.url} target="_blank" rel="noreferrer">
                          <FaFacebookF />
                        </a>
                      </li>
                    );
                  case "vimeo":
                    return (
                      <li>
                        <a href={e.link.url} target="_blank" rel="noreferrer">
                          <FaVimeo />
                        </a>
                      </li>
                    );
                  case "instagram":
                    return (
                      <li>
                        <a href={e.link.url} target="_blank" rel="noreferrer">
                          <FaInstagram />
                        </a>
                      </li>
                    );
                  case "linkedin":
                    return (
                      <li>
                        <a href={e.link.url} target="_blank" rel="noreferrer">
                          <FaLinkedinIn />
                        </a>
                      </li>
                    );
                  case "youtube":
                    return (
                      <li>
                        <a href={e.link.url} target="_blank" rel="noreferrer">
                          <FaYoutube />
                        </a>
                      </li>
                    );
                  default:
                    return null;
                }
              })}
            </ul>
          )} */
